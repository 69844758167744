import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import TransHook from "../../hooks/translation/trans-hook";
import CheckoutHook from "../../hooks/cart/CheckoutHook";
import GetPaymentMethodsHook from "../../hooks/PaymentMethods/GetPaymentMethodsHook";
import AddressesHook from "../../hooks/profile/AddressesHook";

const CartAddressesHook = (cartId) => {
  const { t } = TransHook();
  const {
    addressGet,
    activeShipIndex,
    activeBilIndex,
    addingAddress,
    alias,
    fullName,
    city,
    details,
    postalCode,
    phone,
    activeCart,
    differentBilling,
    userData,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    save,
    cancel,
    setDifferentBilling,
    setAddingAddress,
    selectShipAddress,
    selectBilAddress,
  } = CheckoutHook(cartId);

  const { paymentMethods } = GetPaymentMethodsHook();
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));

  // Calculate the total product cost and apply the addition rate
  const calculateTotalProductCost = () => {
    let totalProductCost = 0;

    activeCart?.cartItems?.forEach((item) => {
      totalProductCost += item.taxPrice * item.quantity;
    });

    return totalProductCost;
  };

  const totalProductCost = calculateTotalProductCost();

  const [onlinePrice, setOnlinePrice] = useState("");
  const [additionRate, setAdditionRate] = useState(0);
  const screen = localStorage.getItem("screen") || "large";

  useEffect(() => {
    if (paymentMethods) {
      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );

      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const companyRatio = onlinePaymentMethod?.companyRatio || 0;

      const extraChargeAmount = totalProductCost * (extraCharge / 100);
      const companyPortion = extraChargeAmount * (companyRatio / 100);
      const customerExtraCharge = extraChargeAmount - companyPortion;
      const finalPrice = totalProductCost + customerExtraCharge;

      setAdditionRate(extraCharge);
      setOnlinePrice(finalPrice);
    }
  }, [paymentMethods, totalProductCost]);

  const [showEdit, setShowEdit] = useState(false);
  const [editAddressId, setEditAddressId] = useState(null);
  const { getOneAddress, setFormData } = AddressesHook();
  const handleEditAddress = (id) => {
    if (id) {
      getOneAddress(id)
        .then((data) => {
          setFormData(data);
          setEditAddressId(id);
          setShowEdit(true);
        })
        .catch((error) => toast.error(`${t("failedToFetchData")} ${error}`));
    }
  };

  const savedAddress = JSON.parse(localStorage.getItem("savedShippingAddress"));

  return {
    screen,
    addressGet,
    activeShipIndex,
    activeBilIndex,
    addingAddress,
    alias,
    fullName,
    city,
    details,
    postalCode,
    phone,
    activeCart,
    differentBilling,
    showEdit,
    cur,
    additionRate,
    onlinePrice,
    editAddressId,
    userData,
    savedAddress,
    setShowEdit,
    handleEditAddress,
    handleAliasChange,
    handleFullNameChange,
    handleCityChange,
    handleDetailsChange,
    handlePostalCodeChange,
    handlePhoneChange,
    save,
    cancel,
    setDifferentBilling,
    setAddingAddress,
    selectShipAddress,
    selectBilAddress,
  };
};

export default CartAddressesHook;
