import React, { useEffect, useState } from "react";
import ShoppingCart from "../../components/Cart/ShoppingCart";
import Checkout from "../../components/Cart/Checkout";
import Navbar from "./../../components/utility/navbar/navbar";
import Footer from "./../../components/utility/Footer/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransHook from "../../hooks/translation/trans-hook";
import AddressDetails from "../../components/Cart/AddressDetails";
import { useNavigate } from "react-router-dom";

const CartPage = ({ active }) => {
  const { t } = TransHook();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(active || "shoppingCart");
  const [cart, setCart] = useState();
  const [cartId, setCartId] = useState();
  const screen = localStorage.getItem("screen") || "large";

  const proceedToAddress = (cartId) => {
    setCart(cartId);
    setActiveTab("address");
    navigate("/cart-address");
  };
  const proceedToCheckout = (cartId) => {
    setCart(cartId);
    setActiveTab("checkout");
    navigate("/checkout");
  };

  useEffect(() => {
    setCartId(cart);
  }, [cart]);

  const goBackToCart = () => {
    setActiveTab("shoppingCart");
    navigate("/cart");
    localStorage.removeItem("cart");
  };
  const goBackToAddress = () => {
    setActiveTab("address");
    navigate("/cart-address");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="container"
        style={{ marginTop: screen === "large" ? "240px" : "120px" }}
      >
        <ul className="checkout-progress-bar d-flex justify-content-center flex-wrap">
          <li>
            <span className={activeTab === "shoppingCart" && "text-dark"}>
              {t("shoppingCart")}
            </span>
          </li>

          <li>
            <span className={activeTab === "address" && "text-dark"}>
              {t("address")}
            </span>
          </li>

          <li>
            <span className={activeTab === "checkout" && "text-dark"}>
              {t("checkout")}
            </span>
          </li>
        </ul>

        {activeTab === "shoppingCart" && (
          <ShoppingCart proceedToAddress={proceedToAddress} />
        )}
        {activeTab === "address" && (
          <AddressDetails
            cartId={cart}
            goBackToCart={goBackToCart}
            proceedToCheckout={proceedToCheckout}
          />
        )}
        {activeTab === "checkout" && (
          <Checkout goBackToAddress={goBackToAddress} cartId={cartId} />
        )}
      </div>
      <ToastContainer autoClose={2000} />
      <Footer />
    </>
  );
};

export default CartPage;
