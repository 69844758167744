import { useEffect, useMemo, useRef, useState } from "react";
import { useGetProductsQuery } from "../../RTK/Api/ProductApi";

const ProductsPageHook = (id, type, keyword) => {
  const ID = id || "";
  const Type = type || "";
  const [limit] = useState(16);
  const [skip, setSkip] = useState(0);
  const [productList, setProductList] = useState([]);
  const [brands, setBrands] = useState([]);
  const initialBrands = useRef([]);
  const [categories, setCategories] = useState([]);
  const [loadingMore, setLoadingMore] = useState(true);
  const [sort, setSort] = useState("default");
  const [taxPriceMax, seTaxPriceMax] = useState(null);
  const [taxPriceMin, setTaxPriceMin] = useState(null);
  const [minAvg, setMinAvg] = useState(null);
  const [maxAvg, setMaxAvg] = useState(null);
  const [brandId, setBrandId] = useState([]);

  // Get the product according to Category
  const {
    data: products,
    isLoading,
    error,
    refetch,
  } = useGetProductsQuery({
    brandId,
    minAvg,
    maxAvg,
    type: Type || undefined,
    id: ID || undefined,
    limit,
    skip,
    keyword: keyword || undefined,
    sort,
    taxPriceMin,
    taxPriceMax,
  });

  const hasMoreProducts = useMemo(() => {
    if (!products) return false;
    const totalProductsLoaded = productList.length + products.data.length;
    const totalProductsAvailable = products.Pages * limit;
    //return totalProductsLoaded < totalProductsAvailable;
    return products.results >= limit;
  }, [products, productList, limit]);

  useEffect(() => {
    if (products) {
      const testDontRemove = hasMoreProducts; // IMPORTANT: DO NOT REMOVE
      setCategories(products?.data[0]?.category);
      const brandsArray = products?.data
        ?.map((product) => {
          const brand = product?.brand[0];
          return brand ? { id: brand._id, name: brand.name } : null;
        })
        .filter((brand) => brand !== null);

      const uniqueBrands = [...new Set(brandsArray.map(JSON.stringify))].map(
        JSON.parse
      );

      // Update initialBrands if it's empty (first fetch)
      if (initialBrands.current.length === 0) {
        initialBrands.current = uniqueBrands;
      }
      // Combine initial brands with new ones
      const combinedBrands = [
        ...initialBrands.current,
        ...uniqueBrands.filter(
          (brand) => !initialBrands.current.some((b) => b.id === brand.id)
        ),
      ];

      setBrands(combinedBrands);
    }
  }, [products]);

  useEffect(() => {
    if (skip === 0) {
      setProductList(products?.data || []);
    } else if (!loadingMore) {
      setProductList((prevList) => {
        const newProducts = products?.data.filter(
          (product) => !prevList.some((p) => p._id === product._id)
        );
        return [...prevList, ...newProducts];
      });
    }
    setLoadingMore(false);
  }, [skip, products]);

  const handleLoadMoreClick = () => {
    setSkip((prevSkip) => prevSkip + limit);
    setLoadingMore(true);
    refetch();
  };

  useEffect(() => {
    setSkip(0);
    refetch();
  }, [
    brandId,
    minAvg,
    maxAvg,
    taxPriceMin,
    taxPriceMax,
    sort,
    Type,
    ID,
    keyword,
  ]);

  return {
    productList,
    isLoading,
    error,
    categories,
    brands,
    loadingMore,
    hasMoreProducts,
    handleSortChange: (sortOption) => {
      setSort(sortOption);
      setSkip(0);
      setProductList([]);
      refetch();
    },
    setProductList,
    setBrandId,
    setTaxPriceMin,
    seTaxPriceMax,
    setMaxAvg,
    setMinAvg,
    limit,
    skip,
    setSkip,
    handleLoadMoreClick,
  };
};

export default ProductsPageHook;
