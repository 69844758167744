import React from "react";
import Navbar from "../../components/utility/navbar/navbar";
import Footer from "../../components/utility/Footer/Footer";
import TransHook from "../../hooks/translation/trans-hook";
import notFound from "../../assets/images/404.png";
import { useNavigate } from "react-router-dom";

const Error404 = () => {
  const { t } = TransHook();
  const navigate = useNavigate();
  const screen = localStorage.getItem("screen") || "large";

  return (
    <>
      <Navbar />
      <h1
        className="text-center text-danger"
        style={{
          fontSize: "40px",
          marginTop: screen === "large" ? "240px" : "120px",
        }}
      >
        {t("error404")}
      </h1>
      <div className="d-flex justify-content-center mt-5">
        <img src={notFound} alt="404" />
      </div>
      <button
        className="btn btn-primary d-block mx-auto mt-5"
        onClick={() => navigate("/")}
      >
        {t("goToHome")}
      </button>
      <Footer />
    </>
  );
};

export default Error404;
