import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Loader from "./../Loaders/Loader";
import "./ShoppingCart.css";
import CartHook from "../../hooks/cart/CartHook";
import TransHook from "./../../hooks/translation/trans-hook";
import CartItem from "./CartItem";
import MobileCartItem from "./MobileCartItem";
import GetPaymentMethodsHook from "../../hooks/PaymentMethods/GetPaymentMethodsHook";

const ShoppingCart = ({ proceedToAddress }) => {
  const {
    isCartLoading,
    isAddLoading,
    isDecLoading,
    cart,
    savedCart,
    cartTotal,
    handleAddToCart,
    handleDecreaseCart,
    handleRemoveItem,
    submitCart,
  } = CartHook(proceedToAddress);

  const { t } = TransHook();
  const lang = localStorage.getItem("language");
  const screen = localStorage.getItem("screen") || "large";
  const cur = JSON.parse(Cookies.get("CompanyCurrency"));

  const { paymentMethods } = GetPaymentMethodsHook();
  const [onlinePrice, setOnlinePrice] = useState("");

  useEffect(() => {
    if (paymentMethods) {
      const onlinePaymentMethod = paymentMethods.find(
        (method) => method.name === "onlinePayment"
      );

      const extraCharge = onlinePaymentMethod?.extraCharge || 0;
      const companyRatio = onlinePaymentMethod?.companyRatio || 0;

      const extraChargeAmount = parseFloat(cartTotal) * (extraCharge / 100);
      const companyPortion = extraChargeAmount * (companyRatio / 100);
      const customerExtraCharge = extraChargeAmount - companyPortion;
      const finalPrice = parseFloat(cartTotal) + customerExtraCharge;

      setOnlinePrice(finalPrice);
    }
  }, [paymentMethods, cartTotal]);

  if (isCartLoading) {
    return <Loader />;
  } else {
    return (
      <div
        className={`row shopping_cart ${lang === "ar" ? "arabic-text" : ""}`}
      >
        {/* Cart items START */}
        <div className="col-lg-8">
          {(isAddLoading || isDecLoading) && (
            <div className="loader-overlay">
              <Loader />
            </div>
          )}
          {(cart?.data?.cartItems || savedCart?.data?.cartItems)?.map(
            (item, i) => {
              return screen === "large" ? (
                <CartItem
                  key={i}
                  item={item}
                  handleAddToCart={handleAddToCart}
                  handleDecreaseCart={handleDecreaseCart}
                  handleRemoveItem={handleRemoveItem}
                />
              ) : (
                <MobileCartItem
                  key={i}
                  item={item}
                  handleAddToCart={handleAddToCart}
                  handleDecreaseCart={handleDecreaseCart}
                  handleRemoveItem={handleRemoveItem}
                />
              );
            }
          )}
        </div>

        {/* Cart totals and shipping START */}
        <div className="col-lg-4">
          <div className="cart-summary">
            <h3>{t("cartTotal")}</h3>

            <table className="table table-totals">
              <tbody>
                <tr>
                  <td>{t("total")}</td>
                  <td>
                    {cur.currencyCode}
                    {parseFloat(onlinePrice).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="checkout-methods">
              <button
                disabled={parseFloat(onlinePrice) <= 0}
                type="submit"
                className="btn btn-block btn-dark"
                onClick={submitCart}
              >
                {t("proceedToAddress")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ShoppingCart;
