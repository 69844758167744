import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  useEditAccountItemMutation,
  useEditAccountPassItemMutation,
} from "../../RTK/Api/AccountApi";
import { toast } from "react-toastify";
import TransHook from "./../translation/trans-hook";

const AccountHook = () => {
  // Navigation hook
  const navigate = useNavigate();

  // Translation hook
  const { t } = TransHook();

  // State for checking if the user is signed in
  const [signedIn, setSignedIn] = useState(!!Cookies.get("userData"));
  const [userInfo, setUserInfo] = useState({});

  // Fetch and check user signed in
  useEffect(() => {
    if (signedIn) {
      const userDataString = Cookies.get("userData");
      const userData = JSON.parse(userDataString);
      setUserInfo(userData);
    }
  }, []);

  // State for toggling editing the details form
  const [editing, setEditing] = useState(false);

  // Toggle editing the details form
  const toggleEditing = () => {
    setTimeout(() => {
      if (editing) {
        setAccName(userInfo?.name);
        setAccEmail(userInfo?.email);
        setAccPhone(userInfo?.phoneNumber);
        setAccIdNumber(userInfo?.idNumber);
      }
    }, 200);
    setEditing(!editing);
  };

  // State for handling and storing account details
  const [accName, setAccName] = useState();
  const [accEmail, setAccEmail] = useState();
  const [accPhone, setAccPhone] = useState();
  const [accIdNumber, setAccIdNumber] = useState();
  const [customerId, setCustomerId] = useState();

  const handleAccNameChange = (e) => setAccName(e.target.value);
  const handleAccEmailChange = (e) => setAccEmail(e.target.value);
  const handleAccPhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    setAccPhone(value);
  };
  const handleAccIdNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setAccIdNumber(value);
  };

  // Populate account details from userInfo
  useEffect(() => {
    setAccName(userInfo?.name);
    setAccEmail(userInfo?.email);
    setAccPhone(userInfo?.phoneNumber);
    setAccIdNumber(userInfo?.idNumber);
    setCustomerId(userInfo?._id);
  }, [userInfo]);

  // State for handling and storing passwords
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

  // Mutations for updating account and password
  const [editAccountPassItem, { errorAccount }] =
    useEditAccountPassItemMutation();
  const [editAccountItem, { errorPass }] = useEditAccountItemMutation();
  if (errorAccount) toast.error(errorAccount);
  if (errorPass) toast.error(errorPass);

  // Sign out function
  const signOut = () => {
    Cookies.remove("Token");
    Cookies.remove("userData");
    localStorage.removeItem("userCart");
    localStorage.removeItem("shipAddress");
    localStorage.removeItem("billAddress");
    localStorage.removeItem("order");
    localStorage.removeItem("cart");
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  // Update password function
  const updatePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error(t("newPassNoMatch"));
      return;
    }

    try {
      await editAccountPassItem({ newPassword }).unwrap();
      signOut();
    } catch (error) {
      toast.error(`${t("failedToUpdate")}: ${error}`);
    }
  };

  // Update profile function
  const updateProfile = async () => {
    if (!accName || !accEmail || !accPhone || !accIdNumber) {
      toast.error(t("emptyFields"));
    } else {
      if (accPhone.length < 5 || accIdNumber.length < 5) {
        toast.error(t("checkPhoneIdLength"));
      } else {
        try {
          const accData = {
            name: accName,
            phoneNumber: accPhone,
            idNumber: accIdNumber,
          };
          await editAccountItem({ newItemData: accData, id: customerId })
            .unwrap()
            .then(() => fulfilled())
            .catch((error) => toast.error(`${t("failedToUpdate")}: ${error}`));
        } catch (error) {
          toast.error(`${t("failedToUpdate")}: ${error}`);
        }
      }
    }
  };

  // Fulfilled callback
  const fulfilled = () => {
    toast.success(t("profileUpdateSuccess"));
    setEditing(false);
  };

  return {
    editing,
    accName,
    accEmail,
    accPhone,
    accIdNumber,
    newPassword,
    confirmPassword,
    toggleEditing,
    handleAccNameChange,
    handleAccEmailChange,
    handleAccPhoneChange,
    handleAccIdNumberChange,
    handleNewPasswordChange,
    handleConfirmPasswordChange,
    updatePassword,
    updateProfile,
  };
};

export default AccountHook;
