import React, { useState } from "react";
import {
  GeoAlt,
  Pencil,
  PersonFill,
  Phone,
  Trash,
} from "react-bootstrap-icons";
import { Button, Modal } from "react-bootstrap";
import "./Checkout.css";
import AddressesHook from "../../hooks/profile/AddressesHook";
import TransHook from "./../../hooks/translation/trans-hook";

const AddressCard = ({
  alias,
  name,
  phone,
  city,
  details,
  postalCode,
  isCommercial,
  taxNo,
  taxAdministration,
  companyName,
  isActive,
  id,
  onEdit,
  canDelete = true,
  canEdit = true,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);

  const { deleteAddress } = AddressesHook();

  const handleAddressRemove = (id) => {
    deleteAddress(id);
    handleCloseConfirm();
  };

  const { t } = TransHook();
  const lang = localStorage.getItem("language");

  return (
    <div
      className={`address-card ${isActive ? "active-address-card" : ""} ${
        lang === "ar" ? "arabic-text" : ""
      }`}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <GeoAlt size={14} />
          &nbsp;<h4 className="m-0">{alias}</h4>
        </div>
        <div className="d-flex">
          {canEdit && <Pencil size={14} onClick={() => onEdit(id)} />}
          &nbsp;
          {canDelete && (
            <Trash size={14} onClick={() => setShowConfirm(true)} />
          )}
        </div>
      </div>
      <hr className="m-0 mb-1" />
      <div className="d-flex my-2" style={{ justifyContent: "space-between" }}>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <PersonFill size={14} />
          &nbsp;<p className="font-14 m-0">{name}</p>
        </div>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <Phone size={14} />
          &nbsp;<p className="font-14 m-0">{phone}</p>
        </div>
      </div>
      <p className="my-2 font-14">
        {details?.length > 50 ? details.slice(0, 50) + "..." : details}
      </p>
      <h5 className="font-14">
        {city} - {postalCode}
      </h5>
      {isCommercial && (
        <>
          <hr />
          <div className="justify-content-between d-flex">
            <p className="m-0">{companyName}</p>
            <p className="m-0">{taxNo}</p>
          </div>
        </>
      )}
      <Modal show={showConfirm} onHide={handleCloseConfirm}>
        <Modal.Header>
          <Modal.Title>{t("caution")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>{t("addressDeleteConfirm")}</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirm}>
            {t("cancel")}
          </Button>
          <Button variant="primary" onClick={() => handleAddressRemove(id)}>
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddressCard;
