import { useEffect, useState } from "react";
import {
  useAddCartItemMutation,
  useDecreaseCartItemMutation,
  useGetCartInfoQuery,
  useRemoveCartItemMutation,
} from "../../RTK/Api/CartApi";
import { toast } from "react-toastify";
import TransHook from "./../translation/trans-hook";
import Cookies from "js-cookie";

const CartHook = (proceedToAddress) => {
  const [cartTotal, setCartTotal] = useState();
  const { t } = TransHook();

  const [addCartItem, { isLoading: isAddLoading, error: addItemError }] =
    useAddCartItemMutation();
  if (addItemError) toast.error(t("failedToAddToCart"));

  const {
    data: cart,
    error: cartError,
    isLoading: isCartLoading,
    refetch,
  } = useGetCartInfoQuery();
  if (cartError) toast.error(cartError);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const data = Cookies.get("userData");
    if (data) {
      setUserData(JSON.parse(data));
    }
    window.scrollTo(0, 0);
  }, []);

  const [savedCart, setSavedCart] = useState();
  const cartString = localStorage.getItem("userCart");
  useEffect(() => {
    if (!userData && cartString) {
      setSavedCart(JSON.parse(cartString));
    }
  }, [userData, cartString]);

  const activeCart = cart?.data ? cart : savedCart;

  const handleAddToCart = async (product) => {
    const { qr, taxPrice } = product;
    const storedCart = JSON.parse(localStorage.getItem("userCart"));
    const currentCartItems = storedCart?.data?.cartItems || [];

    const currentCartItem = currentCartItems.find((item) => item.qr === qr);
    const totalQuantityInCart = currentCartItem ? currentCartItem.quantity : 0;

    const availableStock = product.product.stocks.reduce(
      (total, stock) => total + stock.productQuantity,
      0
    );

    if (totalQuantityInCart >= availableStock) {
      toast.error(t("cantAddMore"));
      return;
    }

    const cartItem = {
      qr,
      taxPrice,
      quantity: 1,
      tax: {
        taxId: product.product.tax._id,
        taxRate: product.product.tax.tax,
      },
      profitRatio: product.product.profitRatio,
      user: userData?._id,
    };

    await addCartItem(cartItem)
      .unwrap()
      .then((response) => {
        const userCart = {
          status: response.status,
          numOfCartItems: response.numOfCartItems,
          data: {
            _id: response.data._id,
            cartItems: response.data.cartItems,
            customar: response.data.customar,
            createdAt: response.data.createdAt,
            updatedAt: response.data.updatedAt,
            totalCartPrice: response.data.totalCartPrice,
          },
        };

        localStorage.setItem("userCart", JSON.stringify(userCart));
        toast.success(t("addedToCart"));
      })
      .catch((error) => {
        console.error("Failed to add to cart:", error);
        toast.error(t("failedToAddToCart"));
      });
  };

  const [
    decreaseCartItem,
    { isLoading: isDecLoading, error: decreaseItemError },
  ] = useDecreaseCartItemMutation();
  if (decreaseItemError) toast.error(t("cantDecrease"));

  const handleDecreaseCart = async (product) => {
    const { quantity } = product;

    await decreaseCartItem({
      itemId: product.product._id,
      quantity: quantity - 1,
    })
      .unwrap()
      .then((response) => {
        const updatedCart = {
          ...activeCart,
          data: {
            ...activeCart.data,
            cartItems: response.cartItems,
            totalCartPrice: response.totalCartPrice,
          },
        };
        localStorage.setItem("userCart", JSON.stringify(updatedCart));
        toast.success(t("amountDecreased"));
      })
      .catch((error) => toast.error(error));
  };

  const [removeCartItem, { error: removeError }] = useRemoveCartItemMutation();
  if (removeError) toast.error(removeError);

  const handleRemoveItem = async (itemId) => {
    try {
      await removeCartItem(itemId)
        .unwrap()
        .then((response) => {
          const updatedCart = {
            ...activeCart,
            data: {
              ...activeCart.data,
              cartItems: response.cartItems,
              totalCartPrice: response.totalCartPrice,
            },
          };
          localStorage.setItem("userCart", JSON.stringify(updatedCart));
          toast.success(t("itemRemoved"));
          refetch();
        });
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    if (!isCartLoading) {
      const totalPrice = activeCart?.data?.totalCartPrice || 0;
      setCartTotal(totalPrice);
    }
  }, [isCartLoading, activeCart]);

  const submitCart = () => {
    if (activeCart?.data?._id !== null && activeCart?.data?._id !== undefined) {
      proceedToAddress(activeCart?.data?._id);
      const cartString = JSON.stringify(activeCart?.data);
      localStorage.setItem("cart", cartString);
    }
  };

  return {
    isCartLoading,
    isAddLoading,
    isDecLoading,
    cart,
    savedCart,
    activeCart,
    cartTotal,
    handleAddToCart,
    handleDecreaseCart,
    handleRemoveItem,
    submitCart,
  };
};

export default CartHook;
